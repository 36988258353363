.crypto-article {
  min-width: 65%;
  // position: sticky;
  top: 9rem;
  overflow: hidden;
}
.leftcolumn {
  height: 75vh;
  margin-top: 30px;
}
.leftcolumn::-webkit-scrollbar {
  width: 3px;
}

/* Fake image */
.fakeimg {
  box-shadow: rgba(0, 0, 0, 0.3) 5px 8px 12px;
  background-color: white;
  margin: 10px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.crypto-img {
  width: 80%;
  height: 80%;
  border-radius: 10px;
}

/* Add a card effect for articles */
.article {
  height: 100%;
  padding: 10px;
  padding-top: 0%;
  text-align: justify;
  font-size: small;
  overflow-y: scroll;
  overflow-x: hidden;
  // position: sticky;
  top: 9rem;
  h2 {
    // position: sticky;
    top: 0px;
    // background-color: #ffffff;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #005edd;
    font-size: 1.5rem;
  }
  button {
    background-color: #005edd;
    border: 0;
    color: #ffffff;
    padding: 0.5rem 1rem;
    border-radius: 5px;
  }
}

// .crypto-btn {
//   padding-left: 5px;
//   padding-top: 10px;
// }
// .readmore {
//   padding-left: 8px;
//   padding-right: 5px;
//   color: blue;
// }
// .readmore:hover {
//   color: black;
// }
