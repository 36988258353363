.tox-notifications-container {
  display: none;
}

h1 {
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
}

h3 {
  margin: 0.4rem 0%;
  font-size: 0.8rem;
  font-weight: 700;
}

.modalBackground {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(15px);
  /* background-color: #f9f9f9; */
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 111;
}

img.uploaded_image-editprofile {
  width: 84px;
  height: 84px;
}

.upload-profile-picture {
  display: inline-flex;
  justify-content: center;
}

/* .img {
  display: inline-flex;
  float: right;
} */
.modalContainer {
  width: 35%;
  /* height: 80%; */
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.input-edit-profile {
  width: 96%;
  border: 2px solid #eee;
  border-radius: 10px;
  height: 2rem;
  padding: 0 2%;
}

.input-edit-profile-about-me {
  width: 100%;
  border: 2px solid #eee;
  border-radius: 10px;
  height: auto;
  padding: 2% 2%;
}

textarea.input-edit-profile-about-me {
  resize: none;
  width: 96%;
}

.modalContainer .title {
  display: inline-flex;
  text-align: center;
  font-size: 0.7rem;
  justify-content: space-between;
  align-items: center;
  color: #808080;
}

svg.svg-close {
  height: 1rem;
  width: 1rem;
  cursor: pointer;
}

svg.svg-close:hover {
  fill: red;
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transition: 0.5s;
}

/* .titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
} */
button.reset-btn {
  padding: 0.2rem 0.4rem;
  margin: 0 1rem;
  border: none;
  font-size: 0.8rem;
  background: #005edd;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}

button.reset-btn:hover {
  background: #f6ae2d;
  color: #ffffff;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 4rem;
  height: 1.5rem;
  margin: 0.5rem;
  border: none;
  background-color: #f6ae2d;
  color: white;
  border-radius: 8px;
  font-size: 0.7rem;
  cursor: pointer;
}

#cancelBtn {
  background-color: #ee4266;
}

.upload-profile-picture {
  width: 100%;
  border: 2px solid #eee;
  border-radius: 10px;
  height: auto;
  text-align: center;
  overflow: hidden;
}

.svg-profile-picture {
  height: 84px;
  width: 84px;
}

.upload-image {
  width: 84px;
  height: 84px;
}

@media only screen and (max-width: 688px) {
  .modalContainer {
    width: auto;
  }
}
