.downarrow {
  transform: rotate(180deg);
}
.tags {
  width: 90%;
}
.downvote {
  transform: rotate(180deg);
  margin-bottom: 3%;
}
.upvote {
  margin-top: 70px;
}
.vote-num {
  margin-top: 13% !important;
  margin: 10px;
  font-size: x-large;
}
.up-down-vote {
  width: 94%;
}
.right-section {
  width: auto;
}
