.main-content {
  height: 100vh;
}

.all-users-header {
  border-bottom: 2px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 1% 0;

  .all-user-filter-btns {
    display: inline-flex;
    justify-content: flex-end;
    gap: 10px;

    button {
      // color: #ffffff;
      margin: 1%;
      padding: 0.7rem 0.7rem;
      border: 0;
      border-radius: 5px;
      background: #ffffff;
      font-size: 1rem;
      cursor: pointer;
      outline: none;
    }

    // button:nth-of-type(1) {
    //   // border-right: 1px solid #838c95;
    // }
    button:hover {
      // border-radius: 0;
      background: #f6ae2d;
      color: #ffffff;
    }

    button.active {
      background: #005edd;
      color: #ffffff;
    }
  }
}

h1.all-users-title {
  color: #005edd;
  margin-top: 100px;
}

a.all-user-link:-webkit-any-link {
  text-decoration: none;
}

.search {
  max-width: calc(var(calc(50rem / 12)) * 3) !important;
  position: relative;
  display: flex;
}

.searchTerm {
  border: 2px solid #f6ae2d;
  border-right: none;
  padding: 1rem;
  height: 0.5rem;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9dbfaf;
}

.searchTerm:focus {
  color: #f6ae2d;
}

.searchButton {
  width: 2.5rem;
  border: 1px solid #f6ae2d;
  background: #f6ae2d;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 0.5rem;
}

svg.search-svg {
  fill: #fff;
  height: 1.5rem;
  width: 1.5rem;
}

.all-user-cards {
  // max-width: 1200px;
  padding-top: 1rem;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  padding-bottom: 1rem;
}

.all-user-card {
  border: 1px solid #f6ae2d;
  border-radius: 5px;
  padding: 0.5rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.3) 5px 8px 12px;
}

svg.all-user-svg {
  height: 0.8rem;
  width: 0.8rem;
  margin-right: 5%;
}

.all-user-profile-image {
  // width: 30%;
  img {
    border-radius: 100px;
    border: 1px solid #005edd;
    padding: 3px;
    height: 4rem;
    width: 4rem;
  }
}

.all-user-profile-right {
  padding-left: 1rem;
  width: 90%;

  h3 {
    font-weight: 700;
    color: #005edd;
    padding: 0%;
    margin: 0%;
  }

  h4 {
    font-weight: 600;
    padding: 5% 0%;
    margin: 0%;
  }

  span {
    padding: 0%;
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 767px) {
  .all-users-header {
    display: flex;
    flex-direction: column;
  }
  .search {
    margin: 10px;
  }
  .all-users-header .all-user-filter-btns {
    margin-bottom: 10px;
  }
}
