.question-block {
    .card {
        width: 100%;
    }
}

.inside-of-card {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid #d9d9d9;
}

.score-of-card {
    display: inline-flex;
    height: 2rem;
    width: 3rem;
    background: green;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    color: white;
}

.title-of-card {
    width: 70%;
}

.date-of-card {
    justify-content: flex-end;
}