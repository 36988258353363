@font-face {
  font-family: "Inconsolata";
  src: url("../../Inconsolata-Medium.ttf");
}

.navbar-main {
  display: flex;
  width: 98.2%;
  position: fixed;
  align-items: center;
  align-content: center;
  background-color: #fff;
  color: #000;
  padding: 10px 20px;
  font-size: 18px;
  margin: 0;
  z-index: 10 !important;
  height: 30px;

  .navbar-left {
    font-weight: 700;
    font-size: 20px;

    .navbar-logo {
      font-family: Inconsolata;
    }
  }

  .navbar-middle {
    flex-grow: 1;

    .searchbar {
      margin-left: 20px;

      input[type="text"] {
        padding: 5px;
      }
    }
  }

  .navigation {
    position: relative;
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    padding: 0.5rem 0rem;
    background-color: #fff;
    color: black;
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  }

  .brand-name {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-left: 1rem;
  }

  .navigation-menu {
    margin-left: auto;
    ul {
      list-style: none;
      display: flex;
      gap: 10px;
      margin: 0px !important;
      align-items: center;
      align-content: center;

      li {
        font-family: Inconsolata;
        &.active {
          background-color: #005edd;
          color: #ffffff;
        }

        a {
          text-decoration: none;
          color: inherit;
        }

        .connect-btn {
          font-size: 16px;
          cursor: pointer;
          height: 35px;
          width: 80px;
          border-radius: 10px;
        }
        .connect-btn-i {
          font-size: 16px;
          cursor: pointer;
          height: 25px;
          width: 25px;
          border-radius: 100%;
        }
      }
    }

    a:hover {
      background-color: #f6ae2d;
      color: #ffffff;
    }
  }

  .navigation-menu ul {
    display: flex;
    padding: 0;
  }

  .navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
  }

  .navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
  }

  .hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #283b8b;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
    margin-right: 20px;
  }

  .hamburger:hover {
    background-color: #2642af;
  }

  .popup-div {
    text-align: center;
    margin: 10px;
  }

  .popup-text {
    // background-color: #f6ae2d;
    color: linear-gradient(90deg, #2642af, #f6ae2d);
    background-clip: text;
    animation: move 1s linear infinite;
  }
  @keyframes move {
    from {
      background-position: 0 0;
      color: transparent;
    }
    to {
      background-position: 300% 0;
    }
  }
  .popup-btn {
    margin-left: 32%;
    white-space: nowrap;
    font-size: 16px;
    cursor: pointer;
    height: 30px;
    width: 250px;
    border-radius: 10px;
    font-family: "Inconsolata";
  }

  @media screen and (max-width: 786px) {
    .hamburger {
      display: block;
    }

    .navigation-menu ul {
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      height: calc(100vh - 60px);
      background-color: white;
      border-top: 1px solid black;
      display: none;
    }

    .navigation-menu li {
      text-align: center;
      margin: 0;
    }

    .navigation-menu li a {
      color: black;
      width: 100%;
      padding: 1.5rem 0;
    }

    .navigation-menu li:hover {
      background-color: #eee;
    }

    .navigation-menu.expanded ul {
      display: block;
    }
  }
}
.nav-logo {
  height: 70px;
}
