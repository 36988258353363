.main {
  display: flex;
  padding-top: 30px;
  flex: 0.75;
  flex-direction: row;
  //  background: #f2f2f2;
  font-size: large;
  width: 100%;
  gap: 1rem;
}

.content-section {
  width: 70%;
  margin-left: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: -30px;
}
// .right-section {
//   padding: 5px;
//   // width: 30%;
//   // background-color: #ffffff;
//   height: auto;
//   // position: fixed;
//   right: 0%;
//   overflow: auto;
// }

.right-section > .grid-container {
  display: grid;
  box-sizing: border-box;
  background-color: #ffffff;

  grid-template-columns: auto auto;
  // background-color: #ffffff ;
  // padding: 10px;
  // gap: 1rem;
}
.right-section > .grid-container > .grid-item-1 {
  // background-color: rgba(255, 255, 255, 0.8);
  color: #005edd;
  padding: 1px;
  font-size: 20px;
  text-align: center;
}
.right-section > .grid-container > .grid-item-2 {
  // background-color: rgba(255, 255, 255, 0.8);
  color: #005edd;
  padding: 1px;
  font-size: 20px;
  text-align: center;
}
.right-section > .grid-container > .grid-item-3 {
  // background-color: rgba(255, 255, 255, 0.8);
  color: #005edd;
  padding: 1px;
  font-size: 20px;
  text-align: center;
}
.right-section > .grid-container > .grid-item-4 {
  // background-color: rgba(255, 255, 255, 0.8);
  color: #005edd;
  padding: 1px;
  font-size: 20px;
  text-align: center;
}

.main-container {
  display: table;
  clear: both;

  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}
.main-top > h2 {
  font-weight: 400;
  font-size: 25px;
  color: rgba(0, 0, 0, 0.8);
}
.main-top > button {
  padding: 10px;
  background-color: #005edd;
  color: #fff;
  border: 2px solid #007cd446;
  outline: none;
  border-radius: 3px;
  cursor: pointer;
}
.main-dec {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.8);
  justify-content: space-between;
  padding: 0px 0px 10px 0px;
  margin-top: 10px;
}
.main-div {
  background-color: #ffffff;
  padding: 10px;
  width: 95%;
  box-shadow: rgba(0, 0, 0, 0.3) 5px 8px 12px;
  border-radius: 10px;
}
.main-filter {
  display: flex;
  align-items: center;
}
.main-tab > button {
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
}

.main-tabs {
  display: flex;

  margin-right: 20px;

  align-items: center;
}

.main-tab {
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  margin: 10px;
}
.main-tab-btn {
  height: 20px;
  width: 100px;
}
.main-filter-item {
  height: 35px;
  width: 70px;
  display: flex;
  margin: 10px;
  padding: 1px 20px;
  border-radius: 3px;
  border: 1px solid #0095ff;
  background-color: #007cd446;
  font-size: small;
  align-items: center;
  cursor: pointer;
}

.questions {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.question {
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
  border-bottom: 1px solid #eee;
  width: 100%;
}

.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: none;
  align-items: center;
}

.pagination a.active {
  background-color: #0095ff;
  color: white;
  border: 1px solid #0095ff;
  border-radius: 3px;
}
.members {
  background-color: #eceff1;
  width: auto;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
}
.profile-img {
  position: absolute;
  width: auto;
  height: 100px;
  margin-top: 70px;
  margin-left: 50px;
  border-radius: 50%;
  border: 5px solid #007cd4;
}

.text-container {
  position: absolute;
  right: 0;
  margin-right: 300px;
  margin-top: 45px;
  max-width: 230px;
  text-align: center;
}

.title-text {
  color: #263238;
  font-size: 20px;
  font-weight: 600;
  margin-top: 5px;
}

.info-text {
  margin-top: 10px;
  font-size: 18px;
}

.desc-text {
  font-size: 14px;
  margin-top: 10px;
}
.column-1 {
  float: left;
  width: 30%;
  padding: 10px;
}
.column-2 {
  float: left;
  width: 70%;
  padding: 10px;
}
.members {
  display: grid;
}
.text-container {
  display: grid;
  width: auto;
  padding-left: 200px;
  position: sticky;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}

.grid-container-members {
  display: grid;
  grid-template-areas:
    "header header header header header header"
    "menu main main main right right"
    "menu footer footer footer footer footer";
  gap: 10px;
  background-color: #2196f3;
  padding: 10px;
}

.grid-container-members > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
}
.profile-img-1 {
  border: 3px solid #2196f3;
  border-radius: 50%;
}
// .card__title > p {
//   align-items: center;
// }
.cards {
  // background-color: #f2f2f2;
  border-radius: none;
  overflow-x: auto;
}

@media only screen and (min-width: 1024px) {
  .profile-img-1 {
    height: 10vh;
    width: 10vh;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .content-section {
    width: 60%;
  }
  .main-tabs {
    display: flex;
    flex-direction: column;
  }
  .main-div {
    width: 90%;
  }
  .question {
    width: 95%;
  }
  .main {
    padding-top: 50px;
  }
  .profile-img-1 {
    display: none;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .main-tabs {
    display: flex;
    flex-direction: column;
  }
  .content-section {
    width: 85%;
  }
}
