.alert-main {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.179);
  display: flex;
  align-items: center;
  align-content: center;
  z-index: 200;

  .alert-box {
    box-sizing: border-box;
    height: 220px;
    width: 300px;
    margin: 0 auto;
    background-color: black;
    border-radius: 20px;

    .alert-header {
      .title {
        color: white;
        text-align: center;
        font-size: 24px;
        padding: 10px 0;
      }
    }

    .alert-container {
      margin: 0 auto;
      margin-top: 40px;

      .alert-holder {
        display: flex;
        align-items: center;
        align-content: center;
        gap: 20px;

        .image {
          height: 100px;
          overflow: hidden;
          flex: 1 1 auto;
          text-align: center;

          .mm {
            width: 80px;
            height: 80px;
            cursor: pointer;
            z-index: 5000;
          }
        }
      }
    }
  }
}

.-cbwsdk-css-reset {
  display: none !important;
}

.main-content {
  background: #f2f2f2;
  padding-bottom: 0%;
  overflow: auto !important;
}