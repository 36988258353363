.section-loading {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin: 1rem 0;
  min-height: 400px;
  position: fixed;
}

.section-loading ul.list-bars {
  list-style: none;
  display: flex;
  align-items: center;
}

.section-loading ul.list-bars li {
  margin: 0 7px;
  width: 5px;
  border-radius: 10px;
  background-color: #005edd;
  animation: animate 0.8s infinite alternate;
}

.section-loading ul.list-bars li:nth-child(odd) {
  height: 20px;
  animation-delay: 0.6s;
}

.section-loading ul.list-bars li:nth-child(even) {
  height: 10px;
}

@keyframes animate {
  from {
    height: 5px;
  }

  to {
    height: 50px;
  }
}
