.question_heading {
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  width: 100%;
  margin-top: 20px;
  color: rgb(5, 20, 34);
  margin-bottom: 20px;
}
// .input_title {
//   width: 100%;
//   height: 20px;
//   padding: 5px;
//   height: 20px;
// }
.title-heading {
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: center;
}
.title_textfield {
  margin-top: 20px;
}
.body {
  margin-top: 20px;
}
.body_title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: center;
}
.body_textfield {
  margin-bottom: 20px;
  margin-top: 20px;
  width: 103%;
}

.Question_fields {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
}
.tag {
  border-radius: 10px;
}
.question_heading {
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  width: 100%;
  margin-top: 20px;
  color: rgb(5, 20, 34);
}
// .input_title {
//   width: 100%;
//   height: 20px;
//   padding: 5px;
//   height: 20px;
// }
.tittle-heading {
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.body {
  margin-top: 20px;
}
.body_tittle {
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.body_textfield {
  margin-bottom: 20px;
}
.Question_fields {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
}
.tag {
  border-radius: 10px;
  border-color: #f88d8d;
  border-style: dashed;
  border-width: 2px;
  padding: 10px;
}
.tag_title {
  margin-bottom: 10px;
}
.tag-input {
  border-radius: 10px;
  border-color: gray;
  border-style: dashed;
  border-width: 0.5px;
  padding: 10px;
}
.submit-btn {
  margin: 10px;
  height: 30px;
  width: 80px;
  border-radius: 5px;
}
.submit-btn-parent {
  width: 100%;
  text-align: center;
}

.add-load {
  position: fixed;
  top: 0 !important;
  z-index: 10;
  background-color: #ffffff72;
}

//tag style

/* Example Styles for React Tags*/
.app {
  padding: 40px;
  text-align: center;
}

/* Example Styles for React Tags*/

.container {
  margin: auto;
  width: 50%;
}
.ReactTags__tags {
  position: relative;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
  border-radius: 2px;
  display: inline-block;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  border: 1px solid #eee;
  min-width: 150px;
  // Added by Jaydip Patel
  border-radius: 10px;
  padding: 0 8%;
  //
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: gray;
  color: white;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 5px;
}
.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
}
.single_Question {
  width: 80%;

  text-align: center;
  font-size: 1.5rem;
  margin: 0 auto;
  margin-top: 20px;
  padding: 20px;
  border-bottom: #aaa 1px solid;
  margin-bottom: 20px;
}
.single_Question_description {
  width: 80%;
  margin: 0 auto;
  font-size: 1.2rem;
  text-align: justify;
  line-height: 0.7cm;
  padding: 10px;
  border: #aaa 2px double;
  border-radius: 10px;
}
.custome_tags {
  width: 80%;
  padding: 10px;
  border: #000 1px solid;
  margin: 0 auto;
  margin-top: 20px;
}
.tags {
  width: 98%;
  padding: 10px;
  border: #000 2px solid;
  display: flex;
}
.tag {
  width: 100%;
  border: #aaa 1px solid;
  margin-right: 5px;
}
.single-tag {
  width: auto;
  border: rgb(0, 0, 0) 1px solid;
  margin: 5px;
  padding: 5px;
  background-color: #005edd;
  color: #ffffff;
  border-radius: 10px;
  text-align: center;
}
.vote-option {
  margin-top: 10px;
  padding: 0px;
  width: 98%;
}
.up-down-vote {
  border: #000 1px solid;
  width: 100%;
  padding: 5px;
  display: flex;
}
.left-side {
  width: 70%;
}
.up-arrow {
  height: 20px;
}
.profile {
  display: flex;
  border: #000 solid 1px;
  width: 30%;
  padding: 7px;
}
.profile-picture {
  width: 80px;
  height: 100px;
  border: #000 1px dotted;
}
.profile-name {
  width: 100%;
  height: 100px;
  border: #000 1px dotted;
}
.disp-flex {
  display: flex;
}
.rotate {
  transform: rotate(180deg);
}
.Instruction-title {
  margin-bottom: 20px;
  font-size: 1.5rem;
}
.Answers {
  width: 80%;
  margin: 0 auto;
  padding: 10px;
  margin-top: 20px;
}
.Answer-component {
  border: #000 2px solid;
  padding: 10px;
}

.Answer-heading {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
}

.Answer_title {
  border-bottom: #000 1px dashed;
  padding: 5px;
  font-size: 1rem;
  margin-bottom: 10px;
  text-align: justify;
}
.Answer-description {
  padding: 5px;
  text-align: justify;
}
.Answer-engagement {
  display: flex;
  border-top: #000 0.5px dashed;
  margin-top: 20px;
  padding-top: 20px;
}
.answer-voting {
  width: 25% !important;
}
.flex-grow {
  flex-grow: 1;
}
.Text-area {
  text-align: center;
  margin-top: 20px;
}
.featured-image {
  margin: 0 auto;
  border: #000 1px solid;
  max-width: 250px;
  width: 100%;

  height: auto;
  padding: 20px;
  text-align: center;
  margin-top: 20px;
}

.aa_input-featured-image {
  width: 80%;
  height: 20px;
}
.uploaded_image {
  margin: 0 auto;
  display: block;
  width: 200px !important;
  height: auto !important;
  overflow: hidden !important;
  margin-bottom: 10px;
}
.upload-to-cloud-svg {
  width: 200px;
  height: auto;
}
.submit-answer {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
.submit-answer-btn {
  margin-top: 20px;
  padding: 10px;
}
.tox-tinymce {
  height: 610px;
}
.ReactTags__tagInputField {
  border: 2px solid black;
  height: 50px;
  border-radius: 4px;
}
.input_title {
  border: 2px solid black;
  height: 50px;
  border-radius: 4px;
  width: 101%;
  padding: 5px;
}
.left-section {
  width: 65%;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.3) 5px 8px 12px;
  margin-left: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
}
.DisplayFlex {
  display: flex;
}

@media (min-width: 768px) and (max-width: 1023px) {
  div.main {
    .left-section {
      width: 60%;
      margin-left: 20px;
      margin-top: 30px;
      margin-bottom: 30px;
      border-radius: 10px;
    }
  }
}

@media only screen and (max-width: 767px) {
  div.main {
    .left-section {
      margin-right: 25px;
      width: 100%;
      margin-top: 100px;
    }
  }
  .tag {
    width: 95%;
  }
  .input_title {
    width: 99%;
  }
  .featured-image {
    width: 88%;
  }
  .upload-to-cloud-svg {
    width: 220px;
    height: auto;
  }
}
