.background-image {
  // width: 100%;
  height: 330px;
  position: relative;
  background-image: url("https://2code.info/demo/themes/Discy/Main/wp-content/uploads/2019/01/cover.png");
  background-repeat: no-repeat;
  // background-attachment: fixed;
  background-position: 50%;
  background-size: cover;
  display: flex;
  justify-content: center;
  z-index: 0;
  margin-top: 50px;
}

.background-opacity {
  // height: 330px;
  // width: 100%;
  // background: #3d3b30;
  // background-size: cover;
  // display: flex;
  // justify-content: space-evenly;
  // align-items: flex-end;
  // flex-direction: row;
  // flex-wrap: nowrap;
  //
  height: 330px;
  width: 100%;
  position: absolute;
  background: rgb(39, 41, 48, 0.7);
  z-index: -1;
}

.inside-cover-image {
  width: 70%;
  align-content: center;
  position: relative;
}

// .show-at-bottom {
//   display: grid;
// }
.main-container {
  // max-width: 100%;
  width: 80%;
  max-width: 80%;
  margin: 0px auto;
  align-items: center;

  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // padding: 10px;
}

.first-block {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0.25%;
  padding: 1%;

  // .image-outside {
  //   border: 2px solid #005edd;
  //   border-radius: 100%;
  //   padding: 0px;
  //   margin: 0%;
  // }

  img.user-profile-image {
    border: 2px solid #ffffff;
    border-radius: 100%;
    // padding: 1px;
    // display: inline-flex;
    width: 50px;
    height: 50px;
    // padding: 2px;
  }

  .user-info {
    flex-direction: column;
    margin-left: 2%;
    color: #ffffff;
    width: 50%;

    h1 {
      margin: 1.5% 0;
      font-size: 20px;
    }

    h3 {
      margin: 1% 0;
      font-size: 15px;
      font-weight: normal;
    }
  }

  .btns {
    width: auto;
    margin-left: auto;

    button {
      padding: 0.5rem 0.5rem;
      border: none;
      border-radius: 1px;
      background: #005edd;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
      outline: none;
      color: #ffffff;
      border-radius: 5px;
    }

    button:nth-of-type(1) {
      margin: 0 1rem;
    }

    button:hover {
      background: #f6ae2d;
    }
  }
}

.nav-user {
  list-style-type: none;
  text-align: left;
  // margin-top: 2%;
  margin: 0.25%;
  padding: 5px 0;

  .nav-user-bar {
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.3) 5px 8px 12px;
    border-radius: 10px;
    padding: 20px 0px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    button {
      padding: 10px 10px;
    }
  }

  .active {
    background: #005edd;
    color: white;
  }

  .active:hover {
    background: #f6ae2d;
    color: white;
  }

  button {
    margin: 0.25%;
    padding: 5px 10px;
    border: 0;
    border-radius: 5px;
    background: none;
    font-size: 18px;
    cursor: pointer;
    outline: none;
  }

  button:hover {
    background: #f6ae2d;
    color: #ffffff;
    border-radius: 5px;
  }
}

.active-li {
  // border-left: 2px solid #005edd;
  border-radius: 0%;
  background: #005edd;
  color: #ffffff;
}

.active-li:hover {
  // border-left: 2px solid #005edd;
  border-radius: 0%;
}

svg.svg-for-mail {
  margin: 0px 5px 0px 0px;
}

@media only screen and (max-width: 767px) {
  .first-block {
    flex-direction: column;
  }
  .first-block .user-info {
    width: 15px;
    margin: 10px;
  }
  .first-block .btns {
    margin: 10px;
    display: flex;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .first-block .btns {
    display: flex;
  }
}
